import React from 'react';
import Sectitle from './Title/Sectitle';
import ErpServiceitem from './Service/ErpServiceitem';

const Services =()=> {
  return(
      <section className="erp_service_area sec_pad" id="services">
        <div className="container">
          <Sectitle sClass="hosting_title erp_title text-center" Title='Services' TitleP='We help companies turn ideas into business changing bespoke software solutions.'/>
          <div className="row">
            <ErpServiceitem itemClass="pr_70" sTitle="Enterprise Software Development" pText="Create complex enterprise software, ensure reliable software integration, modernise your legacy system." icon="software_development.png"/>
            <ErpServiceitem itemClass="pr_70 pl_10" sTitle="Web application development" pText="Utilising the latest technologies, we build web applications that deliver remarkable functionality and user engagement from within a browser window." icon="website.png"/>
            <ErpServiceitem itemClass="pl_70" sTitle="Cloud Computing" pText="Use cloud computing solutions to create a scalable, flexible, and connected enterprise environment." icon="cloud_blue.png"/>
            <ErpServiceitem itemClass="pr_70" sTitle="UX / UI Design" pText="Build the product you need on time with an experienced team that uses a clear and effective design process." icon="settings.png"/>
            <ErpServiceitem itemClass="pl_10" sTitle="API Development & Software Integration" pText="We integrate bespoke software products with third party tools to improve your data flow and efficiency." icon="bulb.png"/>
            <ErpServiceitem itemClass="pl_70" sTitle="Hire Developers" pText="Rapidly scale up your projects or expand your operation. Hire a single developer or an entire team with proven results. " icon="team.png"/>
          </div>
        </div>
      </section>
  )
}

export default Services;
