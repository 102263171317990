import React from 'react';
import NavBar from '../components/Navbar';
import DesignBanner from '../components/Banner/DesignBanner';
import Service from '../components/Service/Service';
import Subscribe from '../components/Subscribe';
import Footer from '../components/Footer/Footer';
import FooterData from '../components/Footer/FooterData';
import FooterErp from '../components/Footer/FooterErp';
import FooterSecurity from '../components/Footer/FooterSecurity';
import FooterThree from '../components/Footer/FooterThree';
import FooterTwo from '../components/Footer/FooterTwo';
import PrototypeFooter from '../components/Footer/PrototypeFooter';
import OnepageMenu from "../components/OnepageMenu";
import SecurityService from "../components/Service/SecurityService";
import AppWork from "../components/Service/AppWork";
import ErpService from "../components/Service/ErpService";
import HRService from "../components/Service/HRService";
import MarketingService from "../components/Service/MarketingService";
import PaymentService from "../components/Service/PaymentService";
import HostingFeatures from "../components/Service/HostingFeatures";
import HostingService from "../components/Service/HostingService";
import HostingFeaturesTwo from "../components/Service/HostingFeaturesTwo";
import Technologies from "../components/Technologies";
import Services from "../components/Services";
import ServicesData from "../components/ServicesData";
import ServiceData from "../components/Service/ServiceData";
import ErpFeatures from "../components/Features/ErpFeatures";
import Contacts from "../components/Contacts";

export const Home = () => (
    <div className="body_wrapper">
      {/*<CustomNavbar cClass="custom_container p0" hbtnClass="new_btn"/>*/}
      <NavBar mClass="menu_four" nClass="" hbtnClass="new_btn"/>

      {/*  Home   */}
      <DesignBanner/>

      {/*  Services   */}
      <Services/>


      {/*<HostingService/>*/}
      {/*  <HostingFeatures/>*/}
      {/*  <HostingFeaturesTwo/>*/}



      {/*  About   */}

      {/*<Subscribe FooterData={FooterData}/>*/}

      <AppWork ServiceData={ServiceData}/>

      {/*  Contact   */}
      <Contacts/>

      {/*  Footer  */}
      <FooterSecurity FooterData={FooterData}/>
    </div>
);
